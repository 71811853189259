<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Survey Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Title</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Title"
                      v-model="survey.title"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.survey.title.$errors)
                      "
                      @blur="v$.survey.title.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Description</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Description"
                      v-model="survey.description"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.survey.description.$errors)
                      "
                      @blur="v$.survey.description.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Start Date</label
                    >

                    <v-menu
                      ref="surveyStartDateMenu"
                      v-model="surveyStartDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="survey.startDate"
                          placeholder="Select start date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(
                              v$.survey.startDate.$errors
                            )
                          "
                          @input="v$.survey.startDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="survey.startDate"
                        @input="surveyStartDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey End Date</label
                    >

                    <v-menu
                      ref="surveyEndDateMenu"
                      v-model="surveyEndDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="survey.endDate"
                          placeholder="Select end date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.survey.endDate.$errors)
                          "
                          @input="v$.survey.endDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="survey.endDate"
                        @input="surveyEndDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Audience</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Audience"
                      :items="surveyAudienceTypes"
                      item-text="value"
                      item-value="key"
                      v-model="survey.audience"
                      :error-messages="
                        getFieldValidationErrors(v$.survey.audience.$errors)
                      "
                      @blur="v$.survey.audience.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Status"
                      :items="surveyStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="survey.status"
                      :error-messages="
                        getFieldValidationErrors(v$.survey.status.$errors)
                      "
                      @blur="v$.survey.status.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  style="margin-top: 30px"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "surveys-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      survey: {
        title: { required },
        description: { required },
        audience: { required },
        startDate: { required },
        endDate: { required },
        status: { required },
      },
    };
  },
  props: {},
  data() {
    return {
      survey: {
        title: "",
        description: "",
        audience: "",
        startDate: "",
        endDate: "",
        status: "",
        created_at: "",
        updated_at: "",
        numQuestions: "",
        questions: [],
      },
      surveyAudienceTypes: [],
      surveyStartDateMenu: false,
      surveyEndDateMenu: false,
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      this.$http
        .get(url, this.sessionStore.getHttpConfig)
        .then((result) => {
          const survey = result.data.data[0];

          this.survey.id = survey.id;
          this.survey.title = survey.title;
          this.survey.description = survey.description;
          this.survey.audience = survey.audience;
          this.survey.startDate = survey.start_date;
          this.survey.endDate = survey.end_date;
          this.survey.status = survey.status;
          this.survey.created_at = survey.created_at;
          this.survey.updated_at = survey.updated_at;
          this.survey.numQuestions = survey.survey_questions.length;

          this.survey.questions.splice(0);
          for (let i = 0; i < survey.survey_questions.length; i++) {
            this.survey.questions.push({
              position: i + 1,
              id: survey.survey_questions[i].id,
              question: survey.survey_questions[i].question,
              numAnswers:
                survey.survey_questions[i].survey_question_answers.length,
              status: survey.survey_questions[i].status,
            });
          }
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while loading the delivery details. Please try again later or contact support."
          );
          console.error("Error while fetching delivery details: ", error);
        });
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const surveyBody = {
        title: this.survey.title,
        description: this.survey.description,
        audience: this.survey.audience,
        start_date: this.survey.startDate,
        end_date: this.survey.endDate,
        status: this.survey.status,
        created_at: this.survey.created_at,
        updated_at: this.survey.updated_at,
      };

      this.$http
        .post("/surveys", surveyBody, this.sessionStore.getHttpConfig)
        .then(async (result) => {
          this.$notify.success("Survey updated successfully!");
          this.$router.push({
            name: "Edit Survey",
            params: {
              id: result.data.data[0].id.toString(),
            },
          });
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the survey. Please try again later or contact support."
          );
          console.error("Error while updating survey: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.fetchStaticDataByGrouping("surveyAudienceTypes")
      .then((result) => {
        this.surveyAudienceTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.surveyAudienceTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        this.$notify.error(
          "An error occurred while loading contact types. Please try again later or contact support."
        );
        console.error("Error while fetching contact types: ", error);
      });
  },
};
</script>
